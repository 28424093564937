import React, { useState, useEffect, useRef } from "react";
import { fetchOnlineUsers, sendMessage, fetchConversation, markMessagesAsRead } from "../api/pesan";
import Swal from "sweetalert2";
import useTokenValidation from "../hook/TokenValidation";

function PesanPage() {
    useTokenValidation();
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState("");

    const chatContentRef = useRef(null);
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    const currentUserImage = userProfile?.profile_picture || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
    const websocketRef = useRef(null); // WebSocket reference

    const getUsers = async () => {
        setLoading(true);
        try {
            const onlineUsers = await fetchOnlineUsers();
            setUsers(onlineUsers);
        } catch (error) {
            Swal.fire("Error", "Failed to load users", "error");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getUsers();
    }, []);

    useEffect(() => {
        if (selectedUser) {
            const fetchMessages = async () => {
                const conversation = await fetchConversation(selectedUser.id);
                setMessages(conversation);
                await markMessagesAsRead(selectedUser.id);
            };
            fetchMessages();
        }
    }, [selectedUser]);

    // Initialize WebSocket connection for real-time updates
    useEffect(() => {
        if (selectedUser) {
            websocketRef.current = new WebSocket(`wss://your-websocket-url/${selectedUser.id}`);
            websocketRef.current.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === "new_message") {
                    setMessages((prevMessages) => [...prevMessages, data.message]);
                }
                if (data.type === "user_status") {
                    getUsers(); // Update online users list if status changes
                }
            };

            websocketRef.current.onclose = () => {
                console.log("WebSocket connection closed");
            };

            return () => {
                if (websocketRef.current) websocketRef.current.close();
            };
        }
    }, [selectedUser]);

    const handleUserClick = async (user) => {
        setSelectedUser(user);
    };

    const handleSendMessage = async (e) => {
        e.preventDefault();
        if (!newMessage.trim()) return;

        const sentMessage = await sendMessage(selectedUser.id, newMessage);
        setMessages([...messages, sentMessage]);

        // Send new message over WebSocket if connected
        if (websocketRef.current && websocketRef.current.readyState === WebSocket.OPEN) {
            websocketRef.current.send(JSON.stringify({ type: "new_message", message: sentMessage }));
        }
        setNewMessage("");
    };

    const filteredUsers = users.filter((user) =>
        user.username.toLowerCase().includes(searchQuery.toLowerCase())
    );

    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <>
            <section className="section">
                {/* ... your existing UI code */}
                <section className="section">
                    <div className="section-header">
                        <h1>Pesan</h1>
                    </div>
                    <div className="section-body">
                        <h2 className="section-title">Your Connection</h2>
                        <p className="section-lead">
                            Stay connected with others and engage in meaningful discussions with Siskuring!
                        </p>
                        <div className="row align-items-center justify-content-center">
                            {/* User List */}
                            <div className="col-12 col-sm-6 col-lg-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h4>Who's Online?</h4>
                                    </div>
                                    <div className="card-body" style={{ maxHeight: "300px", overflowY: "scroll" }}>
                                        <div className="row mb-3">
                                            <div className="col-9">
                                                <input
                                                    type="text"
                                                    placeholder="Search users..."
                                                    className="form-control"
                                                    value={searchQuery}
                                                    onChange={(e) => setSearchQuery(e.target.value)}
                                                />
                                            </div>
                                            <div className="col-3 text-right">
                                                <i
                                                    className="fas fa-sync-alt"
                                                    onClick={getUsers}
                                                    style={{ cursor: "pointer", fontSize: "1.5em" }}
                                                    title="Refresh Users"
                                                />
                                            </div>
                                        </div>
                                        <ul className="list-unstyled list-unstyled-border">
                                            {loading ? (
                                                <p>Loading...</p>
                                            ) : (
                                                filteredUsers.map((user, index) => (
                                                    <li className="media" key={user.id || index} onClick={() => handleUserClick(user)}>
                                                        <img
                                                            alt="User Avatar"
                                                            className="mr-3 rounded-circle"
                                                            width={50}
                                                            src={user.profile_picture || "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"}
                                                        />
                                                        <div className="media-body">
                                                            <div className="mt-0 mb-1 font-weight-bold">
                                                                {user.username}
                                                            </div>
                                                            <div className="text-small font-weight-600 text-muted">
                                                                <i className="fas fa-circle text-secondary" /> {user.onlineStatus}
                                                            </div>
                                                        </div>
                                                    </li>
                                                ))
                                            )}
                                            {!loading && filteredUsers.length === 0 && <p>No users found.</p>}
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            {/* Chat Box */}
                            <div className="col-12 col-sm-6 col-lg-8">
                                <div className="card chat-box" id="mychatbox">
                                    <div className="card-header">
                                        <h4>Chat with {selectedUser ? selectedUser.username : "Select a user"}</h4>
                                    </div>
                                    <div
                                        className="card-body chat-content"
                                        ref={chatContentRef} // Attach the reference to the chat content container
                                        style={{ overflowY: "scroll", maxHeight: "300px", outline: "none", touchAction: "none" }}
                                    >
                                        {selectedUser && messages.length > 0 ? (
                                            messages.map((message, index) => (
                                                <div
                                                    key={index}
                                                    className={`chat-item ${message.sender === userProfile.id ? "chat-right" : "chat-left"}`}
                                                >
                                                    <img
                                                        src={
                                                            message.sender === userProfile.id
                                                                ? currentUserImage
                                                                : selectedUser.profile_picture ||
                                                                "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                        }
                                                        alt="Avatar"
                                                        className="rounded-circle"
                                                        width={40}
                                                    />
                                                    <div className="chat-details">
                                                        <div className="chat-text">{message.content}</div>
                                                        <div className="chat-time">{new Date(message.timestamp).toLocaleTimeString()}</div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-muted">Select a user to start chatting.</p>
                                        )}
                                    </div>
                                    {selectedUser && (
                                        <div className="card-footer chat-form">
                                            <form onSubmit={handleSendMessage}>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Type a message"
                                                    value={newMessage}
                                                    onChange={(e) => setNewMessage(e.target.value)}
                                                />
                                                <button className="btn btn-primary">
                                                    <i className="far fa-paper-plane" />
                                                </button>
                                            </form>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </section>
        </>
    );
}

export default PesanPage;

