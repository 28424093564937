// pages/Login.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { login } from '../api/auth';
import FaceModal from '../components/modals/FaceModal'; // Import FaceModal
import SweetAlert from '../components/alerts/swal';

function LoginPage({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isFaceModalOpen, setIsFaceModalOpen] = useState(false); // State to manage modal visibility
    const navigate = useNavigate();
    const location = useLocation();

    const handleSubmit = async (e) => {
        e.preventDefault();

        const result = await login(username, password);
        if (result.success) {
            // Call onLogin with tokens to update the state in App
            onLogin(result.data.access, result.data.refresh);

            // Redirect to the requested page or to '/'
            const redirectTo = new URLSearchParams(location.search).get('redirectTo') || '/';
            navigate(redirectTo, { replace: true });
        } else {
            SweetAlert.showAlert("Error", "Invalid username or password.", "error");
        }
    };

    const handleOpenFaceModal = () => {
        setIsFaceModalOpen(true); // Open the FaceModal
    };

    const handleCloseFaceModal = () => {
        setIsFaceModalOpen(false); // Close the FaceModal
    };

    return (
        <>
            <div className="login-page"></div>
            <section className="section">
                <div className="d-flex flex-wrap align-items-stretch">
                    {/* Gambar */}
                    <div
                        className="col-lg-8 col-12 order-lg-1 order-1 min-vh-100 background-walk-y position-relative overlay-gradient-bottom"
                        data-background="assets/img/kampus.jpg"
                    >
                        <div className="absolute-bottom-left index-2">
                            <div className="text-light p-5 pb-2">
                                <div className="mb-2 pb-3">
                                    <h1 className="mb-2 display-4 font-weight-bold">Siskuring</h1>
                                    <h5 className="font-weight-normal text-muted-transparent">
                                        Nikmati Kemudahan Belajar Kapan Saja Dan Dimana Saja
                                    </h5>
                                </div>
                                All rights reserved for{" "}
                                <a
                                    className="text-light bb"
                                    target="_blank"
                                    href="https://siskuring.com"
                                    rel="noreferrer"
                                >
                                    Siskuring
                                </a>
                            </div>
                        </div>
                    </div>

                    {/* Form Login */}
                    <div className="col-lg-4 col-md-6 col-12 order-lg-2 order-2 min-vh-100 bg-white">
                        <div className="p-4 m-3">
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <img
                                    src="https://i.ibb.co.com/pfRJRBH/logo-siskuring.png"
                                    alt="logo"
                                    width="25%"
                                    className="mb-3 mt-2"
                                />
                                <div style={{ marginLeft: 1, fontFamily: "Helvetica" }}>
                                    <h2>SISKURING</h2>
                                </div>
                            </div>
                            <h4 className="text-dark font-weight-normal">
                                Welcome <span className="font-weight-bold">Siskuring</span>
                            </h4>
                            <p className="text-muted">
                                Please login to control and manage everything!
                            </p>

                            <form onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <label htmlFor="username">Username</label>
                                    <input
                                        id="username"
                                        type="text"
                                        className="form-control"
                                        name="username"
                                        tabIndex={1}
                                        value={username}
                                        onChange={(e) => setUsername(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="d-block">
                                        <label htmlFor="password" className="control-label">
                                            Password
                                        </label>
                                    </div>
                                    <input
                                        id="password"
                                        type="password"
                                        className="form-control"
                                        name="password"
                                        tabIndex={2}
                                        value={password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                                <div className="form-group">
                                    <div className="d-block">
                                        <a href="">Lupa Password?</a>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <button
                                        type="submit"
                                        className="btn btn-primary btn-lg btn-block"
                                        tabIndex={4}
                                    >
                                        Login
                                    </button>
                                </div>
                            </form>
                            <div className="text-center text-dark">
                                <p>Atau Masuk Dengan</p>
                            </div>
                            <div className="form-group">
                                <button
                                    type="button"
                                    className="btn btn-primary btn-lg btn-block"
                                    onClick={handleOpenFaceModal} // Open the FaceModal
                                >
                                    <span>
                                        <i className="fa-solid fa-expand mr-2" /> Wajah
                                    </span>
                                </button>
                            </div>
                            <p className="text-center mt-3">
                                Ada kendala?{" "}
                                <a href="mailto:support@siskuring.com">support@siskuring.com</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

            {/* FaceModal Component */}
            <FaceModal
                isOpen={isFaceModalOpen}
                onClose={handleCloseFaceModal}
                onLogin={onLogin} // Pass onLogin to FaceModal
            />
        </>
    );
}

export default LoginPage;
