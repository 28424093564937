import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import * as QuizAPI from '../../../api/quiz';

function PreviewQuizModal({ isOpen, onClose, quizId }) {
    const [quizData, setQuizData] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);

    useEffect(() => {
        if (isOpen && quizId) {
            fetchQuiz();
        }
    }, [isOpen, quizId]);

    const fetchQuiz = async () => {
        try {
            const existingQuiz = await QuizAPI.fetchQuizByTopic(quizId);
            if (existingQuiz) {
                const data = await QuizAPI.fetchQuizById(existingQuiz.id);
                if (data) {
                    setQuizData(data);
                } else {
                    Swal.fire('Error', 'Failed to fetch quiz details', 'error');
                }

            }
        } catch (error) {
            Swal.fire('Error', 'Failed to initialize quiz', 'error');
            console.error('Error initializing quiz:', error);
        }

    };

    const nextQuestion = () => {
        setCurrentQuestionIndex((prev) => Math.min(prev + 1, quizData.questions.length - 1));
    };

    const prevQuestion = () => {
        setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0));
    };

    if (!quizData) return null;

    const currentQuestion = quizData.questions[currentQuestionIndex];

    return isOpen ? (
        <div className="modal show fade d-block" style={{ backgroundColor: "rgba(0,0,0,0.5)" }} role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Quiz Preview: {quizData.title}</h5>

                    </div>
                    <div className="modal-body">
                        <h4>{currentQuestion.question_text}</h4>
                        {currentQuestion.question_image && (
                            <img src={currentQuestion.question_image} alt="Question" className="img-fluid mb-3" />
                        )}
                        <div>
                            {currentQuestion.question_type === 'mcq' && (
                                currentQuestion.options.map((option) => (
                                    <div key={option.id}>
                                        <input type="radio" name={`question_${currentQuestion.id}`} />
                                        <label>{option.option_text}</label>
                                    </div>
                                ))
                            )}
                            {currentQuestion.question_type === 'checkbox' && (
                                currentQuestion.options.map((option) => (
                                    <div key={option.id}>
                                        <input type="checkbox" name={`question_${currentQuestion.id}`} />
                                        <label>{option.option_text}</label>
                                    </div>
                                ))
                            )}
                            {currentQuestion.question_type === 'text' && (
                                <textarea className="form-control" placeholder="Type your answer here"></textarea>
                            )}
                            {currentQuestion.question_type === 'match' && (
                                currentQuestion.match_options.map((option, index) => (
                                    <div key={index} className="d-flex align-items-center">
                                        <span>{option.left_option}</span>
                                        <span className="mx-2">→</span>
                                        <span>{option.right_option}</span>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={prevQuestion} className="btn btn-secondary" disabled={currentQuestionIndex === 0}>
                            Previous
                        </button>
                        <button onClick={nextQuestion} className="btn btn-primary" disabled={currentQuestionIndex === quizData.questions.length - 1}>
                            Next
                        </button>
                        <button type="button" className="btn btn-danger" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}

export default PreviewQuizModal;
