import React, { useState, useRef, useEffect } from "react";

function DiscussPage() {
    const [messages, setMessages] = useState([]); // To store chat messages
    const [newMessage, setNewMessage] = useState(""); // For input message
    const chatContentRef = useRef(null); // To scroll chat content

    // Scroll to the bottom of chat content when messages update
    useEffect(() => {
        if (chatContentRef.current) {
            chatContentRef.current.scrollTop = chatContentRef.current.scrollHeight;
        }
    }, [messages]);

    // Handle sending a new message
    const handleSendMessage = (e) => {
        e.preventDefault();
        if (newMessage.trim()) {
            setMessages([...messages, { sender: "You", content: newMessage, timestamp: new Date() }]);
            setNewMessage(""); // Clear the input after sending
        }
    };

    return (
        <>
            <section className="section">
                <section className="section">
                    <div className="section-header">
                        <h1>Diskusi</h1>
                    </div>
                    <div className="section-body">
                        <h2 className="section-title">Forum Diskusi</h2>
                        <p className="section-lead">Silahkan berdiskusi terkait pertemuan</p>
                        <div className="row align-items-center justify-content-center">
                            {/* Chat Box */}
                            <div className="col-12 col-sm-12 col-lg-12">
                                <div className="card chat-box" id="mychatbox">
                                    <div className="card-header">
                                        <h4>Pengenalan dan Sejarah Basis Data</h4>
                                    </div>
                                    <div
                                        className="card-body chat-content"
                                        ref={chatContentRef}
                                        style={{
                                            overflowY: "scroll",
                                            maxHeight: "300px",
                                            outline: "none",
                                            touchAction: "none",
                                        }}
                                    >
                                        {messages.length > 0 ? (
                                            messages.map((message, index) => (
                                                <div
                                                    key={index}
                                                    className={`chat-item ${message.sender === "You" ? "chat-right" : "chat-left"}`}
                                                >
                                                    <img
                                                        src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png"
                                                        alt="Avatar"
                                                        className="rounded-circle"
                                                        width={40}
                                                    />
                                                    <div className="chat-details">
                                                        <div className="chat-text">{message.content}</div>
                                                        <div className="chat-time">
                                                            {message.timestamp.toLocaleTimeString()}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        ) : (
                                            <p className="text-muted">Belum ada percakapan dimulai dalam diskusi ini.</p>
                                        )}
                                    </div>
                                    <div className="card-footer chat-form">
                                        <form className="d-flex" onSubmit={handleSendMessage}>
                                            <input
                                                type="text"
                                                className="form-control me-2"
                                                placeholder="Ketikkan sesuatu"
                                                value={newMessage}
                                                onChange={(e) => setNewMessage(e.target.value)}
                                                style={{
                                                    display: "block",
                                                    visibility: "visible",
                                                    opacity: 1,
                                                }}
                                            />
                                            <button
                                                type="submit"
                                                className="btn btn-primary"
                                                style={{
                                                    display: "block",
                                                    visibility: "visible",
                                                    opacity: 1,
                                                }}
                                            >
                                                <i className="far fa-paper-plane" />
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        </>
    );
}

export default DiscussPage;
