// src/api/pesan.js
// src/api/pesan.js

import moment from 'moment';
const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`;
const token = localStorage.getItem("access_token");

const headers = {
    'Authorization': `Bearer ${token}`,
    'Content-Type': 'application/json',
};

export const fetchOnlineUsers = async () => {
    try {
        const response = await fetch(`${baseUrl}/api/auth/users/`, { headers });
        if (!response.ok) throw new Error('Failed to fetch users');

        const users = await response.json();

        const currentUserProfile = JSON.parse(localStorage.getItem("userProfile"));
        const currentUserId = currentUserProfile?.id;

        // Filter out the current user and calculate online status based on `last_login`
        return users
            .filter(user => user.id !== currentUserId)
            .map(user => {
                const lastLogin = moment(user.last_login);
                const now = moment();

                const minutesAgo = now.diff(lastLogin, 'minutes');
                let onlineStatus;
                if (minutesAgo <= 5) {
                    onlineStatus = 'Online';
                } else if (minutesAgo < 60) {
                    onlineStatus = `${minutesAgo} min ago`;
                } else if (minutesAgo < 1440) {
                    onlineStatus = `${Math.floor(minutesAgo / 60)} hr ago`;
                } else {
                    onlineStatus = `${Math.floor(minutesAgo / 1440)} day ago`;
                }

                return {
                    ...user,
                    onlineStatus,
                };
            });
    } catch (error) {
        console.error('Error fetching users:', error);
        return [];
    }
};


// 1. Send a Message
export const sendMessage = async (recipientId, content) => {
    try {
        const response = await fetch(`${baseUrl}/api/pesan/messages/send/`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ recipient: recipientId, content }),
        });
        return await response.json();
    } catch (error) {
        console.error("Error sending message:", error);
        throw error;
    }
};

// 2. List Messages in a Conversation
export const fetchConversation = async (recipientId) => {
    try {
        const response = await fetch(`${baseUrl}/api/pesan/messages/conversation/${recipientId}/`, {
            method: 'GET',
            headers,
        });
        return await response.json();
    } catch (error) {
        console.error("Error fetching conversation:", error);
        throw error;
    }
};

// 3. Mark Messages as Read
export const markMessagesAsRead = async (recipientId) => {
    try {
        const response = await fetch(`${baseUrl}/api/pesan/messages/${recipientId}/read/`, {
            method: 'POST',
            headers,
        });
        return response.ok;
    } catch (error) {
        console.error("Error marking messages as read:", error);
        throw error;
    }
};
