import React, { useState, useRef } from "react";
import useTokenValidation from "../../hook/TokenValidation";
import { useLocation, Link } from 'react-router-dom';
import SweetAlert from "../../components/alerts/swal";
import { useNavigate } from 'react-router-dom';

function ProfilPage() {
    const navigate = useNavigate();
    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    const location = useLocation();
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    const role = userProfile ? userProfile.role : null;
    const username = userProfile ? userProfile.username : null;
    const email = userProfile ? userProfile.email : null;
    const tgl_lahir = userProfile ? userProfile.birthdate : null;
    const hp = userProfile ? String(userProfile.phone_number) : null;
    const nama = userProfile ? userProfile.first_name : null;
    const nama2 = userProfile ? userProfile.last_name : null;
    const token = localStorage.getItem('access_token');
    const nama_lengkap = `${nama} ${nama2}`;
    const last_login = userProfile ? userProfile.last_login : null;
    const userId = userProfile ? userProfile.id : null;

    useTokenValidation();

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [feedbackMessage, setFeedbackMessage] = useState("");

    // State for managing camera modal and captured image
    const [isCameraModalOpen, setIsCameraModalOpen] = useState(false);
    const [capturedImage, setCapturedImage] = useState(null);
    const videoRef = useRef(null);
    const canvasRef = useRef(null);

    const openCameraModal = () => {
        setIsCameraModalOpen(true);
        startCamera();
    };

    const closeCameraModal = () => {
        stopCamera();
        setIsCameraModalOpen(false);
    };

    const startCamera = () => {
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                videoRef.current.srcObject = stream;
            })
            .catch((error) => {
                console.error("Error accessing the camera", error);
            });
    };

    const stopCamera = () => {
        const stream = videoRef.current?.srcObject;
        if (stream) {
            const tracks = stream.getTracks();
            tracks.forEach((track) => track.stop());
        }
    };

    const captureImage = () => {
        const canvas = canvasRef.current;
        const video = videoRef.current;
        const context = canvas.getContext("2d");
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        const imageUrl = canvas.toDataURL("image/png");
        setCapturedImage(imageUrl);
        stopCamera();
        setIsCameraModalOpen(false);

        // After capturing, send the image for embedding
        uploadEmbedding(imageUrl);
    };

    const uploadEmbedding = async (imageDataUrl) => {
        try {
            // Convert DataURL to Blob for uploading
            const response = await fetch(imageDataUrl);
            const blob = await response.blob();

            // Create FormData and append necessary fields
            const formData = new FormData();
            formData.append("user_id", userId); // Include the user ID in the form data
            formData.append("username", username); // Include the user ID in the form data
            formData.append("input_image", blob, "captured_image.png"); // Attach the image blob

            // Send FormData to backend
            const res = await fetch(`${apiUrl}/api/auth/recognize-face/`, {
                method: "POST",
                headers: {
                    "Authorization": `Bearer ${token}`
                },
                body: formData
            });

            // Log response for debugging
            console.log("Response status:", res.status);

            if (res.ok) {
                const data = await res.json();
                console.log("Recognition response:", data);
                SweetAlert.showAlert(
                    "Success",
                    data.recognized ? "Face recognized successfully" : "Face not recognized",
                    "success",
                    "Ok"
                );
            } else {
                const errorData = await res.json();
                console.error("Error response:", errorData);
                SweetAlert.showAlert(
                    "Oops!",
                    errorData.message || "Failed to recognize Face ID",
                    "error",
                    "Close"
                );
            }
        } catch (error) {
            console.error("Error uploading embedding:", error);
            SweetAlert.showAlert(
                "Oops!",
                "There was a server error. Please try again later.",
                "error",
                "Close"
            );
        }
    };



    const handlePasswordUpdate = async (e) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            SweetAlert.showAlert(
                "Oops!",
                "Konfirmasi Password Tidak Sesuai",
                "error",
                "Tutup"
            )
            return;
        }

        try {
            const response = await fetch(apiUrl + `/api/auth/update-password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({
                    current_password: currentPassword,
                    new_password: newPassword,
                    confirm_new_password: confirmNewPassword
                })
            });

            const result = await response.json();

            if (response.ok) {
                SweetAlert.showAlert(
                    "Berhasil",
                    "Password diperbarui",
                    "success",
                    "Ok"
                ).then(() => {
                    navigate("/", { replace: true });
                });
            } else {
                if (result.confirm_new_password) {
                    SweetAlert.showAlert(
                        "Oops!",
                        "Password Konfirmasi Tidak Sama",
                        "error",
                        "Tutup"
                    )
                } else if (result.new_password_same) {
                    SweetAlert.showAlert(
                        "Oops!",
                        "Password Tidak Boleh Sama Dengan Password Saat Ini",
                        "error",
                        "Tutup"
                    )
                } else if (result.current_password) {
                    SweetAlert.showAlert(
                        "Oops!",
                        "Password Saat Ini Salah",
                        "error",
                        "Tutup"
                    )
                }
            }
        } catch (error) {
            SweetAlert.showAlert(
                "Oops!",
                "Terjadi Error Pada Server",
                "error",
                "Tutup"
            )
        }
    };

    const [img, setImg] = useState(
        JSON.parse(localStorage.getItem('userProfile'))?.profile_picture ||
        "https://www.shutterstock.com/image-vector/vector-illustration-color-avatar-user-260nw-2463110213.jpg"
    );

    const handleImageClick = () => {
        document.getElementById("fileInput").click();
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            setImg(URL.createObjectURL(file));

            const formData = new FormData();
            formData.append("profile_picture", file);

            try {
                const response = await fetch(apiUrl + `/api/auth/update-profile-picture/`, {
                    method: "POST",
                    headers: {
                        "Authorization": `Bearer ${token}`
                    },
                    body: formData
                });

                if (response.ok) {
                    const data = await response.json();
                    setImg(data.profile_picture_url);
                    SweetAlert.showAlert(
                        "Berhasil!",
                        "Foto Profil Berhasil Diperbarui",
                        "success",
                        "Ok"
                    ).then(() => {
                        if (userProfile) {
                            userProfile.profile_picture = data.profile_picture_url;
                            localStorage.setItem('userProfile', JSON.stringify(userProfile));
                        }
                        navigate("/", { replace: true });
                    });
                } else {
                    SweetAlert.showAlert(
                        "Oops!",
                        "Gagal Memperbarui Foto Profil",
                        "error",
                        "Tutup"
                    )
                }
            } catch (error) {
                SweetAlert.showAlert(
                    "Oops!",
                    "Terjadi Kesalahan Pada Server, Silahkan Coba Beberapa Saat Lagi",
                    "error",
                    "Tutup"
                )
            }
        }
    };

    return (
        <section className="section">
            <div className="section-header">
                <h1>Profil</h1>
            </div>
            <div className="section-body">
                <h2 className="section-title">Hi, {nama}!</h2>
                <p className="section-lead">
                    Ubah Informasi Data Diri Kamu Disini.
                </p>
                <div className="row mt-sm-4">
                    <div className="col-12 col-md-12 col-lg-5">
                        <div className="card profile-widget">
                            <div className="profile-widget-header">
                                <img
                                    alt="profile"
                                    src={img || capturedImage}
                                    className="rounded-circle profile-widget-picture"
                                    onClick={handleImageClick}
                                    style={{ cursor: "pointer" }}
                                />
                                <input
                                    id="fileInput"
                                    type="file"
                                    accept="image/*"
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                />
                            </div>
                            <div className="profile-widget-description">
                                <div className="profile-widget-name">
                                    {nama_lengkap}
                                    <div className="text-muted d-inline font-weight-normal">
                                        <div className="slash" /> {role}
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer">
                                <form onSubmit={handlePasswordUpdate} className="needs-validation" noValidate>
                                    <div className="card-header">
                                        <h4>Ubah Password</h4>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="form-group col-md-12 col-12">
                                                <label>Password Saat Ini</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    value={currentPassword}
                                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-12 col-12">
                                                <label>Password Baru</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    value={newPassword}
                                                    onChange={(e) => setNewPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                            <div className="form-group col-md-12 col-12">
                                                <label>Konfirmasi Password Baru</label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    value={confirmNewPassword}
                                                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="submit" className="btn btn-primary">Update Password</button>
                                        {feedbackMessage && (
                                            <p className="mt-2 text-danger">{feedbackMessage}</p>
                                        )}
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-7">
                        <div className="card">
                            <form className="needs-validation" noValidate>
                                <div className="card-header">
                                    <h4>Biodata</h4>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="form-group col-md-6 col-12">
                                            <label>Nama Depan</label>
                                            <input type="text" className="form-control" defaultValue={nama} readOnly />
                                        </div>
                                        <div className="form-group col-md-6 col-12">
                                            <label>Nama Belakang</label>
                                            <input type="text" className="form-control" defaultValue={nama2} readOnly />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6 col-12">
                                            <label>Email</label>
                                            <input type="email" className="form-control" defaultValue={email} readOnly />
                                        </div>
                                        <div className="form-group col-md-6 col-12">
                                            <label>Phone</label>
                                            <input type="tel" className="form-control" defaultValue={hp} readOnly />
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="form-group col-md-6 col-12">
                                            <label>Tanggal Lahir</label>
                                            <input type="date" className="form-control" defaultValue={tgl_lahir} readOnly />
                                        </div>
                                        <div className="form-group col-md-6 col-12">
                                            <label>Nomor Induk Mahasiswa</label>
                                            <input type="text" className="form-control" defaultValue={username} readOnly />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <button onClick={openCameraModal} className="btn btn-primary mt-3">
                            Tambahkan atau Coba Face ID
                        </button>
                    </div>
                </div>
            </div>

            {/* Camera Modal */}
            {isCameraModalOpen && (
                <div className="modal-overlay">
                    <div className="modal-content">
                        <video ref={videoRef} autoPlay playsInline width="100%" />
                        <button onClick={captureImage} className="btn btn-success mt-3">Capture</button>
                        <button onClick={closeCameraModal} className="btn btn-secondary mt-2">Close</button>
                        <canvas ref={canvasRef} width="640" height="480" style={{ display: "none" }} />
                    </div>
                </div>
            )}
        </section>
    );
}

export default ProfilPage;
