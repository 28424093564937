import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';

import { useNavigate, useLocation } from 'react-router-dom';

function FaceModal({ isOpen, onClose, onLogin }) {
    const [username, setUsername] = useState(''); // State to store the username
    const navigate = useNavigate();
    const location = useLocation();

    const apiUrl = process.env.REACT_APP_API_BASE_URL;
    useEffect(() => {
        let stream = null;
        let video = null; // Declare video element inside the useEffect

        // Function to start the camera
        const startCamera = () => {
            video = document.getElementById('video'); // Get the video element
            if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
                navigator.mediaDevices
                    .getUserMedia({ video: true })
                    .then((mediaStream) => {
                        stream = mediaStream;
                        if (video) {
                            video.srcObject = stream;
                            video.play();
                        }
                    })
                    .catch((err) => {
                        console.error("Error accessing the camera: ", err);
                    });
            } else {
                console.error("Camera access is not supported by this browser.");
            }
        };

        // Function to stop the camera
        const stopCamera = () => {
            if (stream) {
                stream.getTracks().forEach((track) => track.stop());
                stream = null;
            }
        };

        // Start or stop the camera based on the modal's open state
        if (isOpen) {
            startCamera();
        } else {
            stopCamera();
        }

        // Clean up the camera when the component unmounts or when the modal closes
        return () => {
            stopCamera();
        };
    }, [isOpen]);

    // Function to capture an image from the video stream
    const captureImage = () => {
        const video = document.getElementById('video'); // Get the video element
        const canvas = document.createElement('canvas');
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Convert the canvas to a data URL (base64-encoded image)
        const imageData = canvas.toDataURL('image/png');
        return imageData;
    };

    // Function to handle face recognition
    const handleFaceRecognition = async () => {
        if (!username) {
            Swal.fire("Error", "Username is required.", "error");
            return;
        }

        const imageData = captureImage();

        // Prepare the form data to send to the API
        const formData = new FormData();
        const blob = await fetch(imageData).then(res => res.blob());
        formData.append('input_image', blob);
        formData.append('username', username); // Append username to form data

        // Send the image and username to the face recognition API
        const response = await fetch(`${apiUrl}/api/auth/recognize-face/`, {
            method: 'POST',
            body: formData
        });

        const result = await response.json();

        if (response.ok && result.recognized) {
            // Face recognized: call onLogin and store values in localStorage
            localStorage.setItem('access_token', result.access_token);
            localStorage.setItem('refresh_token', result.refresh_token);
            localStorage.setItem('userProfile', JSON.stringify(result.user_profile));

            onLogin(result.access_token, result.refresh_token);
            Swal.fire("Success", "Face recognized. You are now logged in!", "success");


            // Redirect to the requested page or to '/'
            const redirectTo = new URLSearchParams(location.search).get('redirectTo') || '/';
            navigate(redirectTo, { replace: true });
            onClose();
        } else {
            // Face not recognized
            Swal.fire("Error", "Wajah belum terdaftar. Silakan login dengan username dan password.", "error");
        }

    };

    return (
        <div
            className={`modal fade ${isOpen ? 'show d-block' : ''}`}
            id="cameraModal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="cameraModalLabel"
            aria-hidden={!isOpen}
        >
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="cameraModalLabel">
                            Akses Kamera
                        </h5>
                        <button
                            type="button"
                            className="close"
                            aria-label="Close"
                            onClick={onClose} // Close modal and stop camera
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label htmlFor="username">Username</label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)} // Update username state
                            />
                        </div>
                        <video id="video" width="100%" autoPlay></video>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleFaceRecognition} // Capture image and handle face recognition
                        >
                            Cek Wajah
                        </button>
                        <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={onClose} // Close modal and stop camera
                        >
                            Tutup
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default FaceModal;
