import React, { useState, useEffect } from 'react';
import * as QuizAPI from '../../../api/quiz';
import Swal from 'sweetalert2';

function QuizModal({ isOpen, onClose, topicId, topicName, onQuizCreated }) {
    const [quizId, setQuizId] = useState(null);
    const [questionId, setQuestionId] = useState(null);
    const [newQuestion, setNewQuestion] = useState({
        question_text: '',
        question_type: 'mcq',
        question_image: null,
        bobot: 0,
    });
    const [freeTextAnswer, setFreeTextAnswer] = useState('');
    const [newAnswerOption, setNewAnswerOption] = useState('');
    const [isCorrect, setIsCorrect] = useState(false);
    const [matchOptions, setMatchOptions] = useState({ left_option: '', right_option: '' });

    useEffect(() => {
        if (isOpen && topicId) {
            initializeQuiz();
        }
    }, [isOpen, topicId]);

    const initializeQuiz = async () => {
        try {
            const existingQuiz = await QuizAPI.fetchQuizByTopic(topicId);
            if (existingQuiz) {
                setQuizId(existingQuiz.id);
            } else {
                const topicData = await QuizAPI.fetchTopikById(topicId);
                const createdQuiz = await QuizAPI.createQuiz({
                    topic: topicId,
                    title: topicData.topic_name,
                    description: `kuis untuk materi ${topicData.topic_name}`,
                });
                if (createdQuiz) {
                    setQuizId(createdQuiz.id);
                    onQuizCreated(createdQuiz);
                    Swal.fire('Success', 'Quiz created automatically!', 'success');
                }
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to initialize quiz', 'error');
            console.error('Error initializing quiz:', error);
        }
    };

    const handleAddQuestion = async () => {
        if (!quizId) return Swal.fire('Error', 'Quiz not created yet', 'error');

        const questionData = new FormData();
        questionData.append('quiz', quizId);
        questionData.append('question_text', newQuestion.question_text);
        questionData.append('question_type', newQuestion.question_type);
        questionData.append('bobot', newQuestion.bobot);
        if (newQuestion.question_image) {
            questionData.append('question_image', newQuestion.question_image);
        }

        try {
            const createdQuestion = await QuizAPI.addQuestionToQuiz(questionData);
            if (createdQuestion) {
                setQuestionId(createdQuestion.id);
                setNewQuestion({ question_text: '', question_type: 'mcq', question_image: null, bobot: 0 });
                Swal.fire('Success', 'Question added successfully!', 'success');
            }
        } catch (error) {
            console.error('Error adding question:', error);
            Swal.fire('Error', 'Failed to add question', 'error');
        }
    };

    const handleAddFreeTextAnswer = async () => {
        if (!questionId) return Swal.fire('Error', 'Create a question before adding an answer', 'error');

        const freeTextAnswerData = {
            question: questionId,
            option_text: freeTextAnswer,
            is_correct: true,
        };

        try {
            const createdAnswer = await QuizAPI.addAnswerOption(freeTextAnswerData);
            if (createdAnswer) {
                Swal.fire('Success', 'Free text answer added successfully!', 'success');
                setFreeTextAnswer('');
                handleFinishQuestion();
            }
        } catch (error) {
            console.error('Error adding free text answer:', error);
            Swal.fire('Error', 'Failed to add free text answer', 'error');
        }
    };

    const handleAddAnswerOption = async () => {
        if (!questionId) return Swal.fire('Error', 'Create a question before adding answer options', 'error');

        const answerOptionData = {
            question: questionId,
            option_text: newAnswerOption,
            is_correct: isCorrect,
        };

        try {
            const createdAnswerOption = await QuizAPI.addAnswerOption(answerOptionData);
            if (createdAnswerOption) {
                Swal.fire('Success', 'Answer option added successfully!', 'success');
                setNewAnswerOption('');
            }
        } catch (error) {
            console.error('Error adding answer option:', error);
            Swal.fire('Error', 'Failed to add answer option', 'error');
        }
    };

    const handleAddMatchOption = async () => {
        if (!questionId) return Swal.fire('Error', 'Create a question before adding match options', 'error');

        const matchOptionData = {
            question: questionId,
            left_option: matchOptions.left_option,
            right_option: matchOptions.right_option,
        };

        try {
            const createdMatchOption = await QuizAPI.addMatchOption(matchOptionData);
            if (createdMatchOption) {
                Swal.fire('Success', 'Match option added successfully!', 'success');
                setMatchOptions({ left_option: '', right_option: '' });
            }
        } catch (error) {
            console.error('Error adding match option:', error);
            Swal.fire('Error', 'Failed to add match option', 'error');
        }
    };

    const handleFinishQuestion = () => {
        setQuestionId(null);
        setNewQuestion({ question_text: '', question_type: 'mcq', question_image: null, bobot: 0 });
        setNewAnswerOption('');
        setFreeTextAnswer('');
        setMatchOptions({ left_option: '', right_option: '' });
    };

    return isOpen ? (
        <div className="modal show fade d-block" style={{ backgroundColor: "rgba(0,0,0,0.5)" }} role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Quiz: {topicName}</h5>
                    </div>
                    <div className="modal-body">
                        {quizId && !questionId && (
                            <>
                                <label className='form-label'>Question</label>
                                <input
                                    type="text"
                                    placeholder="Question Text"
                                    value={newQuestion.question_text}
                                    onChange={(e) => setNewQuestion({ ...newQuestion, question_text: e.target.value })}
                                    className="form-control mb-2"
                                />
                                <label className='form-label'>Type</label>
                                <select
                                    className="form-control mb-2"
                                    value={newQuestion.question_type}
                                    onChange={(e) => setNewQuestion({ ...newQuestion, question_type: e.target.value })}
                                >
                                    <option value="mcq">Multiple Choice</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="text">Free Text</option>
                                    <option value="match">Match Options</option>
                                </select>
                                <label className='form-label'>File</label>
                                <input
                                    type="file"
                                    onChange={(e) => setNewQuestion({ ...newQuestion, question_image: e.target.files[0] })}
                                    className="form-control mb-2"
                                />
                                <label className='form-label'>Bobot</label>
                                <input
                                    type="number"
                                    placeholder="Bobot (Weight)"
                                    value={newQuestion.bobot}
                                    min="0"
                                    onChange={(e) => setNewQuestion({ ...newQuestion, bobot: parseInt(e.target.value) })}
                                    className="form-control mb-2"
                                />
                                <button onClick={handleAddQuestion} className="btn btn-primary mb-3">
                                    Add Question
                                </button>
                            </>
                        )}

                        {questionId && newQuestion.question_type === 'text' && (
                            <>
                                <textarea
                                    placeholder="Enter expected answer"
                                    value={freeTextAnswer}
                                    onChange={(e) => setFreeTextAnswer(e.target.value)}
                                    className="form-control mb-2"
                                />
                                <button onClick={handleAddFreeTextAnswer} className="btn btn-success mb-3 mx-2">
                                    Selesai
                                </button>
                            </>
                        )}

                        {questionId && (newQuestion.question_type === 'mcq' || newQuestion.question_type === 'checkbox') && (
                            <>
                                <input
                                    type="text"
                                    placeholder="Answer Option Text"
                                    value={newAnswerOption}
                                    onChange={(e) => setNewAnswerOption(e.target.value)}
                                    className="form-control mb-2"
                                />
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={isCorrect}
                                        onChange={(e) => setIsCorrect(e.target.checked)}
                                    />{' '}
                                    Is Correct
                                </label>
                                <button onClick={handleAddAnswerOption} className="btn btn-info mb-3 mx-2">
                                    Add Answer Option
                                </button>
                            </>
                        )}

                        {questionId && newQuestion.question_type === 'match' && (
                            <>
                                <input
                                    type="text"
                                    placeholder="Left Option"
                                    value={matchOptions.left_option}
                                    onChange={(e) => setMatchOptions({ ...matchOptions, left_option: e.target.value })}
                                    className="form-control mb-2"
                                />
                                <input
                                    type="text"
                                    placeholder="Right Option"
                                    value={matchOptions.right_option}
                                    onChange={(e) => setMatchOptions({ ...matchOptions, right_option: e.target.value })}
                                    className="form-control mb-2"
                                />
                                <button onClick={handleAddMatchOption} className="btn btn-info mb-3 mx-2">
                                    Add Match Option
                                </button>
                            </>
                        )}

                        {questionId && (
                            <button onClick={handleFinishQuestion} className="btn btn-success mb-3 mx-2">
                                Selesai
                            </button>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}

export default QuizModal;