function MaintencePage() {
    return (
        <>
            <section className="section">
                <div className="container mt-5">
                    <div className="page-error">
                        <div className="page-inner">
                            <h3>Oops!</h3>
                            <div className="page-description">
                                Halaman ini masih dalam perbaikan.
                            </div>
                            <img className='img-fluid' src='/assets/img/mt.png' alt='Description of the image' style={{ width: '300px', height: 'auto' }} />

                        </div>
                    </div>
                    <div className="simple-footer mt-5">
                        Copyright © Siskuring 2024
                    </div>
                </div>
            </section>
        </>
    )
}

export default MaintencePage;