import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import * as QuizAPI from '../../../api/quiz';

function PreviewQuizModal2({ isOpen, onClose, topicId }) {
    const [quizData, setQuizData] = useState(null);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [userAnswers, setUserAnswers] = useState({}); // Store user's answers

    useEffect(() => {
        if (isOpen && topicId) {
            fetchQuizByTopic();
        }
    }, [isOpen, topicId]);

    const fetchQuizByTopic = async () => {
        try {
            const existingQuiz = await QuizAPI.fetchQuizByTopic(topicId);
            if (existingQuiz) {
                const data = await QuizAPI.fetchQuizById(existingQuiz.id);
                if (data) {
                    if (data.questions.length === 0) {
                        Swal.fire('Info', 'Belum ada kuis saat ini.', 'info');
                        onClose(); // Close the modal if no questions are available
                        return;
                    }
                    setQuizData(data);
                } else {
                    Swal.fire('Error', 'Failed to fetch quiz details', 'error');
                }
            } else {
                Swal.fire('Error', 'No quiz found for this topic', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'Failed to initialize quiz', 'error');
            console.error('Error initializing quiz:', error);
        }
    };

    const handleAnswerChange = (questionId, answer) => {
        setUserAnswers((prevAnswers) => ({
            ...prevAnswers,
            [questionId]: answer,
        }));
    };

    const isAnswerProvided = () => {
        const currentQuestion = quizData.questions[currentQuestionIndex];
        const answer = userAnswers[currentQuestion.id];

        if (currentQuestion.question_type === 'mcq' || currentQuestion.question_type === 'checkbox') {
            return Array.isArray(answer) ? answer.length > 0 : !!answer;
        } else if (currentQuestion.question_type === 'text') {
            return !!answer && answer.trim().length > 0;
        }
        return false;
    };

    const nextQuestion = () => {
        if (isAnswerProvided()) {
            setCurrentQuestionIndex((prev) => Math.min(prev + 1, quizData.questions.length - 1));
        } else {
            Swal.fire('Warning', 'Please answer the question before proceeding.', 'warning');
        }
    };

    const prevQuestion = () => {
        setCurrentQuestionIndex((prev) => Math.max(prev - 1, 0));
    };

    if (!quizData || !quizData.questions || quizData.questions.length === 0) return null;

    const currentQuestion = quizData.questions[currentQuestionIndex];

    // Add a check for currentQuestion before accessing its properties
    if (!currentQuestion) return null;

    return isOpen ? (
        <div className="modal show fade d-block" style={{ backgroundColor: "rgba(0,0,0,0.5)" }} role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Quiz Preview: {quizData.title}</h5>
                        <button type="button" className="close" onClick={onClose}>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <h4>{currentQuestion.question_text}</h4>
                        {currentQuestion.question_image && (
                            <img src={currentQuestion.question_image} alt="Question" className="img-fluid mb-3" />
                        )}
                        <div>
                            {currentQuestion.question_type === 'mcq' && (
                                currentQuestion.options.map((option) => (
                                    <div key={option.id}>
                                        <input
                                            type="radio"
                                            name={`question_${currentQuestion.id}`}
                                            value={option.id}
                                            onChange={() => handleAnswerChange(currentQuestion.id, option.id)}
                                            checked={userAnswers[currentQuestion.id] === option.id}
                                        />
                                        <label>{option.option_text}</label>
                                    </div>
                                ))
                            )}
                            {currentQuestion.question_type === 'checkbox' && (
                                currentQuestion.options.map((option) => (
                                    <div key={option.id}>
                                        <input
                                            type="checkbox"
                                            name={`question_${currentQuestion.id}`}
                                            value={option.id}
                                            onChange={(e) => {
                                                const currentAnswer = userAnswers[currentQuestion.id] || [];
                                                const newAnswer = e.target.checked
                                                    ? [...currentAnswer, option.id]
                                                    : currentAnswer.filter((id) => id !== option.id);
                                                handleAnswerChange(currentQuestion.id, newAnswer);
                                            }}
                                            checked={(userAnswers[currentQuestion.id] || []).includes(option.id)}
                                        />
                                        <label>{option.option_text}</label>
                                    </div>
                                ))
                            )}
                            {currentQuestion.question_type === 'text' && (
                                <textarea
                                    className="form-control"
                                    placeholder="Type your answer here"
                                    value={userAnswers[currentQuestion.id] || ''}
                                    onChange={(e) => handleAnswerChange(currentQuestion.id, e.target.value)}
                                ></textarea>
                            )}
                            {currentQuestion.question_type === 'match' && (
                                currentQuestion.match_options.map((option, index) => (
                                    <div key={index} className="d-flex align-items-center">
                                        <span>{option.left_option}</span>
                                        <span className="mx-2">→</span>
                                        <span>{option.right_option}</span>
                                    </div>
                                ))
                            )}
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button onClick={prevQuestion} className="btn btn-secondary" disabled={currentQuestionIndex === 0}>
                            Previous
                        </button>
                        <button onClick={nextQuestion} className="btn btn-primary" disabled={!isAnswerProvided()}>
                            Next
                        </button>
                        <button type="button" className="btn btn-danger" onClick={onClose}>
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
}

export default PreviewQuizModal2;
