import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';

function ManageAttendanceModal({ isOpen, onClose, sessionId, token }) {
    const [attendanceRecords, setAttendanceRecords] = useState([]); // State to store attendance records
    const [studentsData, setStudentsData] = useState({}); // State to store fetched student data

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    // Fetch attendance records for the session
    const fetchAttendanceRecords = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/class/attendance_records/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                // Filter records by session ID
                const filteredRecords = data.filter(record => record.attendance_session === sessionId);
                setAttendanceRecords(filteredRecords);

                // Fetch student data for each record
                await fetchStudentData(filteredRecords);
            } else {
                throw new Error('Failed to fetch attendance records.');
            }
        } catch (error) {
            console.error("Error fetching attendance records:", error);
            Swal.fire("Gagal", "Terjadi kesalahan saat mengambil data kehadiran.", "error");
        }
    };

    // Fetch student data for each record
    const fetchStudentData = async (records) => {
        try {
            const studentRequests = records.map(record =>
                fetch(`${baseUrl}/api/auth/users/${record.student}/`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`,
                    }
                }).then(res => res.json())
            );

            const students = await Promise.all(studentRequests);

            // Map student data by their ID for quick lookup
            const studentDataMap = {};
            students.forEach(student => {
                studentDataMap[student.id] = student;
            });

            setStudentsData(studentDataMap);
        } catch (error) {
            console.error("Error fetching student data:", error);
        }
    };

    // Handle status change for attendance record
    const handleStatusChange = async (recordId, newStatus) => {
        try {
            // Find the current record to extract required fields
            const recordToUpdate = attendanceRecords.find(record => record.id === recordId);

            if (!recordToUpdate) {
                Swal.fire("Gagal", "Rekam kehadiran tidak ditemukan.", "error");
                return;
            }

            const response = await fetch(`${baseUrl}/api/class/attendance_records/${recordId}/`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
                body: JSON.stringify({
                    attendance_session: recordToUpdate.attendance_session,
                    student: recordToUpdate.student,
                    enrollment: recordToUpdate.enrollment,
                    status: newStatus
                })
            });

            if (response.ok) {
                // Update the local state to reflect the change
                setAttendanceRecords(prevRecords =>
                    prevRecords.map(record =>
                        record.id === recordId ? { ...record, status: newStatus } : record
                    )
                );
                Swal.fire("Berhasil", "Status kehadiran berhasil diperbarui.", "success");
            } else {
                throw new Error('Gagal memperbarui status kehadiran.');
            }
        } catch (error) {
            console.error("Error updating attendance status:", error);
            Swal.fire("Gagal", "Terjadi kesalahan saat memperbarui status kehadiran.", "error");
        }
    };


    useEffect(() => {
        if (isOpen && sessionId) {
            fetchAttendanceRecords(); // Fetch records when modal opens
        }
    }, [isOpen, sessionId]);

    if (!isOpen) return null;

    return (
        <div className="modal modal-lg show fade d-block" style={{ backgroundColor: "rgba(0,0,0,0.5)" }} role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Kelola Kehadiran</h5>
                        <button type="button" className="close" onClick={onClose}>
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {/* Render attendance records here */}
                        {attendanceRecords.length > 0 ? (
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Mahasiswa</th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attendanceRecords.map((record, index) => (
                                        <tr key={record.id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                {studentsData[record.student]
                                                    ? `${studentsData[record.student].first_name} ${studentsData[record.student].last_name}`
                                                    : 'Loading...'}
                                            </td>
                                            <td>
                                                <Select
                                                    options={[
                                                        { value: "present", label: "Hadir" },
                                                        { value: "absent", label: "Tidak Hadir" },
                                                        { value: "excused", label: "Izin" }
                                                    ]}
                                                    value={{ value: record.status, label: record.status }}
                                                    onChange={(option) => handleStatusChange(record.id, option.value)}
                                                    placeholder="Pilih status"
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        ) : (
                            <p>Tidak ada data kehadiran untuk sesi ini.</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Tutup
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManageAttendanceModal;
