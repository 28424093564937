import React, { useState, useEffect } from "react";
import useTokenValidation from "../../../hook/TokenValidation";

function ListKelasMhsAbsen() {
    useTokenValidation();

    const token = localStorage.getItem("access_token");
    const userProfile = JSON.parse(localStorage.getItem("userProfile"));
    const [classes, setClasses] = useState([]);
    const [loading, setLoading] = useState(true);

    const apiUrl = process.env.REACT_APP_API_BASE_URL;

    // Fetch enrollments and then get classes based on the enrollments
    const fetchClasses = async () => {
        try {
            // Fetch student enrollments
            const enrollmentResponse = await fetch(`${apiUrl}/api/class/class-enrollments/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            const enrollments = await enrollmentResponse.json();

            // Filter enrollments for the current student
            const studentEnrollments = enrollments.filter(
                (enrollment) => enrollment.user === userProfile.id
            );

            // Fetch all classes
            const classResponse = await fetch(`${apiUrl}/api/class/classes/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            const allClasses = await classResponse.json();

            // Filter classes based on the student's enrollments
            const enrolledClasses = allClasses.filter((cls) =>
                studentEnrollments.some((enrollment) => enrollment.class_model === cls.class_id)
            );

            setClasses(enrolledClasses);
        } catch (error) {
            console.error("Error fetching classes:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchClasses();
    }, []);

    return (
        <>
            <section className="section">
                <div className="section-header">
                    <h1>Absensi</h1>
                </div>
                <h2 className="section-title">Kelas yang kamu ikuti</h2>
                {loading ? (
                    <p>Loading classes...</p>
                ) : (
                    <div className="row">
                        {classes.length > 0 ? (
                            classes.map((cls) => (
                                <div key={cls.class_id} className="col-12 col-sm-6 col-md-6 col-lg-4">
                                    <article className="article article-style-b">
                                        <div className="article-header">
                                            <div
                                                className="article-image"
                                                style={{
                                                    backgroundImage: `url(${cls.class_thumbnail || "https://cdn6.f-cdn.com/contestentries/1162950/18299824/59ef996d1981d_thumb900.jpg"})`,
                                                }}
                                            ></div>
                                            <div className="article-badge">
                                                {cls.tag && (
                                                    <div className="article-badge-item bg-danger">
                                                        {cls.tag}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="article-details">
                                            <div className="article-title">
                                                <h2>{cls.class_name_long}</h2>
                                            </div>
                                            <p>{cls.description}</p>
                                            <div className="article-cta">
                                                <a href={`/mhs/absensi/${cls.class_id}`}>
                                                    Kelola Absensi <i className="fas fa-chevron-right" />
                                                </a>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            ))
                        ) : (
                            <p>No classes found.</p>
                        )}
                    </div>
                )}
            </section>
        </>
    );
}

export default ListKelasMhsAbsen;
