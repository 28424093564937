import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import useTokenValidation from "../../../hook/TokenValidation";
import PreviewQuizModal2 from "../../../components/modals/kelas/PreviewQuizModal2";

function KelasMhsDetail() {
    useTokenValidation();
    const { id_kelas } = useParams();
    const navigate = useNavigate();
    const token = localStorage.getItem("access_token");
    const [classDetail, setClassDetail] = useState(null);
    const [topics, setTopics] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
    const [previewTopicId, setPreviewTopicId] = useState(null);

    const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`;

    const fetchClassDetail = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/class/classes/${id_kelas}/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setClassDetail(data);
        } catch (error) {
            console.error("Error fetching class details:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchTopics = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/topic/topics/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            const data = await response.json();
            const filteredTopics = data.filter((topic) => topic.class_model === parseInt(id_kelas));
            setTopics(filteredTopics);
        } catch (error) {
            console.error("Error fetching topics:", error);
        }
    };

    useEffect(() => {
        fetchClassDetail();
        fetchTopics();
    }, [id_kelas]);

    const handleGoToDiscussion = (topicId) => {
        navigate(`/discuss/${topicId}`);
    };

    const handlePreviewQuiz = (topicId) => {
        setPreviewTopicId(topicId);
        setIsPreviewModalOpen(true);
    };

    if (loading) {
        return <p>Loading class details...</p>;
    }

    if (!classDetail) {
        return <p>Class not found.</p>;
    }

    return (
        <>
            <section className="section">
                <div className="section-header">
                    <h1>Kelas</h1>
                </div>
                <h2 className="section-title">{classDetail.class_name_long}</h2>

                {/* Class Description */}
                <div className="col-12 mb-4">
                    <div className="hero text-white hero-bg-image hero-bg-parallax">
                        <div className="hero-inner">
                            <h2>{classDetail.class_name_long}</h2>
                            <p className="lead">{classDetail.description}</p>
                        </div>
                    </div>
                </div>

                {/* Topics Accordion */}
                <div className="card">
                    <div className="card-header">
                        <h4>Pertemuan</h4>
                    </div>
                    <div className="card-body">
                        <div id="accordion">
                            {topics.map((topic) => (
                                <div key={topic.topic_id} className="accordion">
                                    <div
                                        className="accordion-header"
                                        role="button"
                                        data-toggle="collapse"
                                        data-target={`#panel-body-${topic.topic_id}`}
                                        aria-expanded="true"
                                    >
                                        <h4>{topic.topic_name}</h4>
                                    </div>
                                    <div
                                        className="accordion-body collapse"
                                        id={`panel-body-${topic.topic_id}`}
                                        data-parent="#accordion"
                                    >
                                        <div dangerouslySetInnerHTML={{ __html: topic.description }} />
                                        {topic.module_file && (
                                            <a href={topic.module_file} target="_blank" rel="noopener noreferrer" className="btn btn-info mt-2">
                                                Materi
                                            </a>
                                        )}
                                        <button
                                            onClick={() => handleGoToDiscussion(topic.topic_id)}
                                            className="btn btn-warning mt-2 mx-2"
                                        >
                                            Forum Diskusi
                                        </button>

                                        <button
                                            onClick={() => handlePreviewQuiz(topic.topic_id)}
                                            className="btn btn-dark mt-2"
                                        >
                                            Kuis
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Preview Quiz Modal */}
                <PreviewQuizModal2
                    isOpen={isPreviewModalOpen}
                    onClose={() => setIsPreviewModalOpen(false)}
                    topicId={previewTopicId} // Pass the topicId to the modal
                />
            </section>
        </>
    );
}

export default KelasMhsDetail;
