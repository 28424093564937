import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import ManageAttendanceModal from '../../../components/modals/ManageAbsensiModal';
function KelasDosenAbsensiDetail() {
    const { id_kelas } = useParams(); // Retrieve the class ID from the URL
    const [attendanceSessions, setAttendanceSessions] = useState([]); // State to store attendance sessions
    const [selectedSessionId, setSelectedSessionId] = useState(null); // State for selected session ID
    const [isModalOpen, setIsModalOpen] = useState(false); // State for modal open/close
    const token = localStorage.getItem('access_token'); // Retrieve token from local storage

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    // Fetch attendance sessions for the class
    const fetchAttendanceSessions = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/class/attendance_sessions/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                // Filter sessions by class ID
                const filteredSessions = data.filter(session => session.class_model === parseInt(id_kelas));
                setAttendanceSessions(filteredSessions);
            } else {
                throw new Error('Failed to fetch attendance sessions.');
            }
        } catch (error) {
            console.error("Error fetching attendance sessions:", error);
            Swal.fire("Gagal", "Terjadi kesalahan saat mengambil data sesi kehadiran.", "error");
        }
    };

    useEffect(() => {
        fetchAttendanceSessions(); // Fetch attendance sessions when component mounts
    }, [id_kelas]);

    // Handle opening the modal
    const handleOpenModal = (sessionId) => {
        setSelectedSessionId(sessionId);
        setIsModalOpen(true);
    };

    // Handle closing the modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedSessionId(null);
    };

    return (
        <section className="section">
            <div className="section-header">
                <h1>Daftar Absensi</h1>
            </div>
            {attendanceSessions.length > 0 ? (
                <table className="table table-striped mt-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Tanggal Sesi</th>
                            <th>Deskripsi</th>
                            <th>Aksi</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendanceSessions.map((session, index) => (
                            <tr key={session.id}>
                                <td>{index + 1}</td>
                                <td>{new Date(session.session_date).toLocaleDateString()}</td>
                                <td>{session.description}</td>
                                <td>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => handleOpenModal(session.id)}
                                    >
                                        Kelola Kehadiran
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>Tidak ada sesi absensi untuk kelas ini.</p>
            )}

            {/* Manage Attendance Modal */}
            <ManageAttendanceModal
                isOpen={isModalOpen}
                onClose={handleCloseModal}
                sessionId={selectedSessionId}
                token={token}
            />
        </section>
    );
}

export default KelasDosenAbsensiDetail;
