import React, { useState, useEffect } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {
    listPengumuman,
    createPengumuman,
    updatePengumuman,
    deletePengumuman,
} from "../../../api/pengumuman";
import Select from 'react-select';
import { fetchFaculties } from "../../../api/faculty";
import { fetchProdisByFaculty } from "../../../api/prodi";

function ListPengumuman() {
    const token = localStorage.getItem('access_token');
    const [announcements, setAnnouncements] = useState([]);
    const [filteredAnnouncements, setFilteredAnnouncements] = useState([]);
    const [formData, setFormData] = useState({
        faculty: "",
        prodi: "",
        judul: "",
        isi_pengumuman: "",
        image: null,
    });
    const [isEditing, setIsEditing] = useState(false);
    const [editId, setEditId] = useState(null);
    const [faculties, setFaculties] = useState([]);
    const [prodiList, setProdiList] = useState([]);
    const [selectedFaculty, setSelectedFaculty] = useState(null);
    const [selectedProdi, setSelectedProdi] = useState(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: "faculty", direction: "ascending" });

    useEffect(() => {
        fetchAnnouncements();
    }, []);

    const fetchAnnouncements = async () => {
        try {
            const data = await listPengumuman();
            setAnnouncements(data);
            setFilteredAnnouncements(data);
        } catch (error) {
            console.error("Error fetching announcements:", error);
        }
    };

    const handleInputChange = (name, value) => {
        setFormData({ ...formData, [name]: value });
    };

    const handleFileChange = (e) => {
        setFormData({ ...formData, image: e.target.files[0] });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = new FormData();
        data.append("judul", formData.judul);
        data.append("faculty", selectedFaculty ? selectedFaculty.value : "");
        data.append("prodi", selectedProdi ? selectedProdi.value : "");
        data.append("isi_pengumuman", formData.isi_pengumuman);
        if (formData.image) data.append("image", formData.image);

        try {
            if (isEditing) {
                await updatePengumuman(editId, data);
            } else {
                await createPengumuman(data);
            }
            fetchAnnouncements();
            resetForm();
        } catch (error) {
            console.error("Error saving announcement:", error);
        }
    };

    const handleEdit = (announcement) => {
        setFormData({
            faculty: announcement.faculty || "",
            prodi: announcement.prodi || "",
            judul: announcement.judul,
            isi_pengumuman: announcement.isi_pengumuman,
            image: null,
        });

        const selectedFaculty = faculties.find((faculty) => faculty.value === announcement.faculty);
        setSelectedFaculty(selectedFaculty || null);

        const selectedProdi = prodiList.find((prodi) => prodi.value === announcement.prodi);
        setSelectedProdi(selectedProdi || null);

        setIsEditing(true);
        setEditId(announcement.id);
    };

    const handleDelete = async (id) => {
        try {
            await deletePengumuman(id);
            fetchAnnouncements();
        } catch (error) {
            console.error("Error deleting announcement:", error);
        }
    };

    const resetForm = () => {
        setFormData({ faculty: "", prodi: "", judul: "", isi_pengumuman: "", image: null });
        setSelectedFaculty(null);
        setSelectedProdi(null);
        setIsEditing(false);
        setEditId(null);
    };

    const handleFacultyChange = async (selectedOption) => {
        setSelectedFaculty(selectedOption);
        setFormData({ ...formData, faculty: selectedOption ? selectedOption.value : "" });

        if (selectedOption && selectedOption.value !== "") {
            try {
                const prodis = await fetchProdisByFaculty(selectedOption.value, token);
                const prodiOptions = [
                    { value: "", label: "Semua Prodi" },
                    ...prodis.map((prodi) => ({
                        value: prodi.id,
                        label: prodi.name,
                    })),
                ];
                setProdiList(prodiOptions);
                setSelectedProdi(prodiOptions[0]);
            } catch (error) {
                console.error("Error fetching study programs:", error);
            }
        } else {
            setProdiList([]);
            setSelectedProdi(null);
        }
    };

    const handleProdiChange = (selectedOption) => {
        setSelectedProdi(selectedOption);
        setFormData({ ...formData, prodi: selectedOption ? selectedOption.value : "" });
    };

    const sortTable = (key) => {
        let direction = "ascending";
        if (sortConfig.key === key && sortConfig.direction === "ascending") {
            direction = "descending";
        }
        setSortConfig({ key, direction });

        const sortedData = [...filteredAnnouncements].sort((a, b) => {
            const aValue = a[key] || "";
            const bValue = b[key] || "";

            if (aValue < bValue) return direction === "ascending" ? -1 : 1;
            if (aValue > bValue) return direction === "ascending" ? 1 : -1;
            return 0;
        });

        setFilteredAnnouncements(sortedData);
    };

    useEffect(() => {
        const loadFaculties = async () => {
            try {
                const data = await fetchFaculties(token);
                const facultyOptions = [
                    { value: "", label: "Semua Fakultas" },
                    ...data.map((faculty) => ({
                        value: faculty.id,
                        label: faculty.name,
                    })),
                ];
                setFaculties(facultyOptions);
            } catch (error) {
                console.error("Error fetching faculties:", error.message);
            }
        };

        loadFaculties();
    }, [token]);

    const getFacultyName = (facultyId) => {
        const faculty = faculties.find((faculty) => faculty.value === facultyId);
        return faculty ? faculty.label : "Semua Jurusan";
    };

    const getProdiName = (prodiId) => {
        const prodi = prodiList.find((prodi) => prodi.value === prodiId);
        return prodi ? prodi.label : "Semua Program Studi";
    };

    return (
        <section className="section">
            <div className="section-header">
                <h1>Kelola Pengumuman</h1>
            </div>
            <div className="section-body">
                <div className="mb-3">
                    <input
                        type="text"
                        placeholder="Cari Pengumuman"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        className="form-control mb-2"
                    />
                </div>

                <table className="table">
                    <thead>
                        <tr>
                            <th onClick={() => sortTable("judul")}>
                                Judul {sortConfig.key === "judul" ? (sortConfig.direction === "ascending" ? "▲" : "▼") : ""}
                            </th>
                            <th onClick={() => sortTable("faculty")}>
                                Faculty {sortConfig.key === "faculty" ? (sortConfig.direction === "ascending" ? "▲" : "▼") : ""}
                            </th>
                            <th onClick={() => sortTable("prodi")}>
                                Prodi {sortConfig.key === "prodi" ? (sortConfig.direction === "ascending" ? "▲" : "▼") : ""}
                            </th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredAnnouncements.map((announcement) => (
                            <tr key={announcement.id}>
                                <td>{announcement.judul}</td>
                                <td>{getFacultyName(announcement.faculty)}</td>
                                <td>{getProdiName(announcement.prodi)}</td>
                                <td>
                                    <button
                                        className="btn btn-warning btn-sm"
                                        onClick={() => handleEdit(announcement)}
                                    >
                                        Edit
                                    </button>
                                    <button
                                        className="btn btn-danger btn-sm mx-2"
                                        onClick={() => handleDelete(announcement.id)}
                                    >
                                        Delete
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>

                <div className="card">
                    <div className="card-header">
                        <h4>{isEditing ? "Edit Pengumuman" : "Tulis Pengumuman"}</h4>
                    </div>
                    <div className="card-body">
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <label>Faculty</label>
                                <Select
                                    options={faculties}
                                    value={selectedFaculty}
                                    onChange={handleFacultyChange}
                                    placeholder="Select a faculty"
                                />
                            </div>
                            <div className="form-group">
                                <label>Prodi</label>
                                <Select
                                    options={prodiList}
                                    value={selectedProdi}
                                    onChange={handleProdiChange}
                                    placeholder="Select a study program"
                                />
                            </div>
                            <div className="form-group">
                                <label>Judul</label>
                                <input
                                    type="text"
                                    className="form-control"
                                    value={formData.judul}
                                    onChange={(e) => handleInputChange("judul", e.target.value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Isi Pengumuman</label>
                                <ReactQuill
                                    value={formData.isi_pengumuman}
                                    onChange={(value) => handleInputChange("isi_pengumuman", value)}
                                />
                            </div>
                            <div className="form-group">
                                <label>Image (Optional)</label>
                                <input type="file" onChange={handleFileChange} />
                            </div>
                            <button type="submit" className="btn btn-primary">
                                {isEditing ? "Update Pengumuman" : "Create Pengumuman"}
                            </button>
                            {isEditing && (
                                <button
                                    type="button"
                                    className="btn btn-secondary ml-2"
                                    onClick={resetForm}
                                >
                                    Cancel
                                </button>
                            )}
                        </form>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ListPengumuman;
