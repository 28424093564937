import React, { useState, useEffect } from "react";
import useTokenValidation from "../hook/TokenValidation";
import { listPengumuman } from "../api/pengumuman";
import AnnouncementModal from "../components/modals/AnnouncementModal";

function Homepage() {
    useTokenValidation();

    const [announcements, setAnnouncements] = useState([]);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState(null);
    const [showModal, setShowModal] = useState(false);

    // Retrieve user data from local storage
    const userProfile = JSON.parse(localStorage.getItem("userProfiles") || "{}");

    // Helper function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleString("en-GB", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
        });
    };

    useEffect(() => {
        const fetchAnnouncements = async () => {
            try {
                const data = await listPengumuman();

                // Filter announcements based on various faculty and prodi conditions
                const filteredAnnouncements = data.filter((announcement) => {
                    const isGeneral = !announcement.faculty && !announcement.prodi;
                    const matchesFaculty = announcement.faculty === userProfile.faculty || !announcement.faculty;
                    const matchesProdi = announcement.prodi === userProfile.prodi || !announcement.prodi;

                    return isGeneral || (matchesFaculty && matchesProdi);
                });

                setAnnouncements(filteredAnnouncements);
            } catch (error) {
                console.error("Error fetching announcements:", error);
            }
        };

        fetchAnnouncements();
    }, [userProfile.faculty, userProfile.prodi]);

    const handleAnnouncementClick = (announcement) => {
        setSelectedAnnouncement(announcement);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedAnnouncement(null);
    };

    return (
        <>
            <section className="section mt-3">
                <div className="row">
                    <div className="">
                        <div className="card card-hero">
                            <div className="card-header">
                                <div className="card-icon">
                                    <i className="far fa-question-circle" />
                                </div>
                                <h4>{announcements.length}</h4>
                                <div className="card-description">Pengumuman</div>
                            </div>
                            <div className="card-body p-0">
                                <div className="tickets-list">
                                    {announcements.length > 0 ? (
                                        announcements.slice(0, 3).map((announcement) => (
                                            <div
                                                key={announcement.id}
                                                className="ticket-item"
                                                onClick={() => handleAnnouncementClick(announcement)}
                                                style={{ cursor: "pointer" }}
                                            >
                                                <div className="ticket-title">
                                                    <h4>{announcement.judul}</h4>
                                                </div>
                                                <div className="ticket-info">
                                                    <div>{announcement.author || "Siskuring"}</div>
                                                    <div className="bullet" />
                                                    <div className="text-primary">
                                                        {announcement.updated_at
                                                            ? formatDate(announcement.updated_at)
                                                            : "Just now"}
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="ticket-item">
                                            <div className="ticket-title">
                                                <h4>Belum Ada Pengumuman Untuk Kamu Saat Ini</h4>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Render the modal */}
            <AnnouncementModal
                show={showModal}
                onClose={handleCloseModal}
                announcement={selectedAnnouncement}
            />
        </>
    );
}

export default Homepage;
