import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';

function AttendanceModal({ isOpen, onClose, token }) {
    const { id_kelas } = useParams(); // Get class ID from URL
    const [sessionDate] = useState(new Date().toISOString().slice(0, 16)); // Fixed current date and time
    const [students, setStudents] = useState([]); // List of students enrolled in the class
    const [attendanceRecords, setAttendanceRecords] = useState([]); // Stores attendance records for display
    const [topics, setTopics] = useState([]); // List of topics for the class
    const [selectedTopic, setSelectedTopic] = useState(null); // Selected topic for the session

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    // Fetch topics related to the class
    const fetchTopics = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/topic/topics/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            const data = await response.json();
            const filteredTopics = data.filter((topic) => topic.class_model === parseInt(id_kelas));
            setTopics(filteredTopics);
        } catch (error) {
            console.error("Error fetching topics:", error);
        }
    };

    // Fetch enrolled students for the class
    const fetchEnrollments = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/class/class-enrollments/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });
            const data = await response.json();

            // Filter enrollments for the selected class
            const enrolledStudents = data.filter((enrollment) => enrollment.class_model === parseInt(id_kelas));

            const detailedStudents = await Promise.all(
                enrolledStudents.map(async (enrollment) => {
                    const userResponse = await fetch(`${baseUrl}/api/auth/users/${enrollment.user}/`, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${token}`
                        }
                    });
                    const userData = await userResponse.json();
                    return {
                        value: enrollment.user,
                        label: `${userData.first_name} ${userData.last_name} - Status: ${enrollment.status}`,
                        enrollmentId: enrollment.id,
                        status: 'absent', // Default status; modify as needed
                        userData: userData
                    };
                })
            );

            setStudents(detailedStudents);
            setAttendanceRecords(detailedStudents); // Display all enrollments initially
        } catch (error) {
            console.error("Error fetching enrollments or user data:", error);
        }
    };

    useEffect(() => {
        if (isOpen) {
            fetchEnrollments(); // Load enrolled students when modal opens
            fetchTopics(); // Fetch topics
        }
    }, [isOpen]);

    // Handle attendance status selection for each student
    const handleStatusChange = async (studentId, status) => {
        try {
            // Find the selected student record
            const studentRecord = attendanceRecords.find(record => record.value === studentId);

            if (!studentRecord) {
                Swal.fire("Gagal", "Data mahasiswa tidak ditemukan.", "error");
                return;
            }

            // Step 1: Check if the attendance session exists for the class and session date (date only)
            let sessionId;
            const sessionCheckResponse = await fetch(`${baseUrl}/api/class/attendance_sessions/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });

            if (sessionCheckResponse.ok) {
                const sessions = await sessionCheckResponse.json();
                // Get today's date as a string (format: YYYY-MM-DD)
                const todayDate = new Date(sessionDate).toISOString().split('T')[0];

                // Find the most recent session for the class and date (using only the date)
                const existingSessions = sessions.filter(session =>
                    session.class_model === parseInt(id_kelas) &&
                    new Date(session.session_date).toISOString().split('T')[0] === todayDate
                );

                if (existingSessions.length > 0) {
                    // Use the last (most recent) session
                    sessionId = existingSessions[existingSessions.length - 1].id;
                }
            }

            // Step 2: If no session exists, create a new one
            if (!sessionId) {
                if (!selectedTopic) {
                    Swal.fire("Error", "Pilih topik sebelum menyimpan sesi kehadiran.", "error");
                    return;
                }

                const createSessionResponse = await fetch(`${baseUrl}/api/class/attendance_sessions/`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        class_model: id_kelas,
                        session_date: sessionDate,
                        description: selectedTopic.label // Use selected topic as description
                    })
                });

                if (!createSessionResponse.ok) {
                    throw new Error('Gagal membuat sesi kehadiran.');
                }

                const newSessionData = await createSessionResponse.json();
                sessionId = newSessionData.id;
            }

            // Step 3: Create or update the attendance record
            let method = "POST";
            let endpoint = `${baseUrl}/api/class/attendance_records/`;

            // Check if the record exists for the given student and session
            const checkResponse = await fetch(`${baseUrl}/api/class/attendance_records/`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            });

            if (checkResponse.ok) {
                const records = await checkResponse.json();
                const existingRecord = records.find(record =>
                    record.attendance_session === sessionId && record.student === studentId
                );

                if (existingRecord) {
                    // Record exists, proceed with PUT
                    method = "PUT";
                    endpoint = `${baseUrl}/api/class/attendance_records/${existingRecord.id}/`;
                }
            }

            // Create or update the attendance record
            const response = await fetch(endpoint, {
                method,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    attendance_session: sessionId,
                    student: studentId,
                    status: status,
                    enrollment: studentRecord.enrollmentId
                })
            });

            if (!response.ok) {
                throw new Error('Gagal memperbarui atau membuat kehadiran.');
            }

            // Update the local state
            setAttendanceRecords((prevRecords) =>
                prevRecords.map((record) =>
                    record.value === studentId ? { ...record, status } : record
                )
            );

            // Show success message
            Swal.fire("Berhasil", "Status kehadiran berhasil diperbarui.", "success");
        } catch (error) {
            console.error("Error updating attendance status:", error);
            Swal.fire("Gagal", "Terjadi kesalahan saat memperbarui status kehadiran.", "error");
        }
    };

    // Handle topic selection
    const handleTopicChange = (option) => {
        setSelectedTopic(option);
    };

    if (!isOpen) return null;

    return (
        <div className="modal modal-lg show fade d-block" style={{ backgroundColor: "rgba(0,0,0,0.5)" }} role="dialog">
            <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Penandaan Kehadiran untuk Kelas</h5>
                    </div>
                    <div className="modal-body">
                        {/* Session Date (Fixed, non-editable) */}
                        <label className='text-danger'>Sesi Absensi Hanya Bisa Dilakukan 1 Kali Dalam Sehari dan Hanya untuk 1 Topik Pembelajaran</label>
                        <br></br>
                        <br></br>
                        <label>Tanggal dan Waktu Sesi:</label>
                        <p className="form-control mb-3">{sessionDate}</p>

                        {/* Topic Selection */}
                        <div>
                            <label>Pilih Topik:</label>
                            <Select
                                options={topics.map((topic) => ({
                                    value: topic.id,
                                    label: topic.topic_name, // Adjust if necessary
                                }))}
                                onChange={handleTopicChange}
                                placeholder="Pilih topik"
                                value={selectedTopic}
                                className="mb-3"
                            />
                        </div>

                        {/* Attendance List */}
                        <h6>Daftar Kehadiran Mahasiswa:</h6>
                        {students.length > 0 ? (
                            <div className="mt-4">
                                <table className="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Mahasiswa</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {attendanceRecords.map((record, index) => (
                                            <tr key={index}>
                                                <td>{index + 1}</td>
                                                <td>{record.label}</td>
                                                <td>
                                                    <Select
                                                        options={[
                                                            { value: "present", label: "Hadir" },
                                                            { value: "absent", label: "Tidak Hadir" },
                                                            { value: "excused", label: "Izin" }
                                                        ]}
                                                        value={{ value: record.status, label: record.status }}
                                                        onChange={(option) => handleStatusChange(record.value, option.value)}
                                                        placeholder="Pilih status"
                                                        className="ml-3"
                                                    />
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <p>Tidak ada mahasiswa yang ditandai untuk kehadiran.</p>
                        )}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" onClick={onClose}>
                            Tutup
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AttendanceModal;
