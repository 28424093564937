import Swal from 'sweetalert2';

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}`;
const token = localStorage.getItem("access_token");

const headers = {
    'Authorization': `Bearer ${token}`,
};

// 1. Fetch all quizzes
export const fetchAllQuizzes = async () => {
    try {
        const response = await fetch(`${baseUrl}/api/quiz/quizzes/`, { headers });
        if (!response.ok) throw new Error('Failed to fetch quizzes');
        return await response.json(); // Returns all quizzes
    } catch (error) {
        console.error('Error fetching all quizzes:', error);
        return [];
    }
};

// 2. Fetch quiz by topic ID
export const fetchQuizByTopic = async (topicId) => {
    try {
        const quizzes = await fetchAllQuizzes(); // Get all quizzes
        return quizzes.find(quiz => quiz.topic === topicId) || null; // Return the quiz for the topic if found
    } catch (error) {
        console.error('Error fetching quiz by topic:', error);
        return null;
    }
};

// 3. Fetch quiz by quiz ID
export const fetchQuizById = async (quizId) => {
    try {
        const response = await fetch(`${baseUrl}/api/quiz/kuis/${quizId}/`, { headers });
        if (!response.ok) throw new Error('Failed to fetch quiz by ID');
        return await response.json(); // Returns quiz details with questions and options
    } catch (error) {
        console.error('Error fetching quiz by ID:', error);
        return null;
    }
};

// 4. Fetch questions for a specific topic
export const fetchQuestionsByTopic = async (topicId) => {
    try {
        const response = await fetch(`${baseUrl}/api/topics/${topicId}/questions/`, { headers });
        if (!response.ok) throw new Error('Failed to fetch questions for topic');
        return await response.json(); // Returns questions for the specified topic
    } catch (error) {
        console.error('Error fetching questions by topic:', error);
        return [];
    }
};

// 5. Create a new quiz
export const createQuiz = async (quizData) => {
    try {
        const response = await fetch(`${baseUrl}/api/quiz/quizzes/`, {
            method: 'POST',
            headers: { ...headers, 'Content-Type': 'application/json' },
            body: JSON.stringify(quizData)
        });
        if (!response.ok) throw new Error('Failed to create quiz');
        return await response.json(); // Returns the created quiz with its ID
    } catch (error) {
        console.error('Error creating quiz:', error);
        return null;
    }
};

// 6. Add a question to a quiz
export const addQuestionToQuiz = async (questionData) => {
    try {
        const response = await fetch(`${baseUrl}/api/quiz/questions/`, {
            method: 'POST',
            headers: questionData instanceof FormData ? { 'Authorization': `Bearer ${token}` } : { ...headers, 'Content-Type': 'application/json' },
            body: questionData instanceof FormData ? questionData : JSON.stringify(questionData)
        });
        if (!response.ok) throw new Error('Failed to add question to quiz');
        return await response.json(); // Returns the created question with its ID
    } catch (error) {
        console.error('Error adding question to quiz:', error);
        return null;
    }
};

// 7. Add an answer option
export const addAnswerOption = async (answerOptionData) => {
    try {
        const response = await fetch(`${baseUrl}/api/quiz/answer-options/`, {
            method: 'POST',
            headers: { ...headers, 'Content-Type': 'application/json' },
            body: JSON.stringify(answerOptionData)
        });
        if (!response.ok) throw new Error('Failed to add answer option');
        return await response.json(); // Returns the created answer option
    } catch (error) {
        console.error('Error adding answer option:', error);
        return null;
    }
};

// 8. Add a match option (for matching question type)
export const addMatchOption = async (matchOptionData) => {
    try {
        const response = await fetch(`${baseUrl}/api/quiz/match-options/`, { // Corrected URL for match options
            method: 'POST',
            headers: { ...headers, 'Content-Type': 'application/json' },
            body: JSON.stringify(matchOptionData)
        });
        if (!response.ok) throw new Error('Failed to add match option');
        return await response.json(); // Returns the created match option
    } catch (error) {
        console.error('Error adding match option:', error);
        return null;
    }
};

// 9. Fetch topic details by ID
export const fetchTopikById = async (topicId) => {
    try {
        const response = await fetch(`${baseUrl}/api/topic/topics/${topicId}/`, { headers });
        if (!response.ok) throw new Error('Failed to fetch topic details');
        return await response.json(); // Returns topic details
    } catch (error) {
        console.error('Error fetching topic by ID:', error);
        return null;
    }
};

// 10. Create a discussion for a quiz topic
export const createDiscussion = async (topicId, quizTitle) => {
    try {
        const response = await fetch(`${baseUrl}/api/discuss/discussions/`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                topic: topicId,
                title: `Discussion on ${quizTitle}`
            })
        });
        if (!response.ok) throw new Error('Failed to create discussion');
        Swal.fire('Success', 'Discussion created successfully!', 'success');
    } catch (error) {
        console.error('Failed to create discussion', error);
        Swal.fire('Error', 'Failed to create discussion', 'error');
    }
};
