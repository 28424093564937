import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function KelasMhsAbsensiDetail() {
    const { id_kelas } = useParams(); // Retrieve the class ID from the URL
    const token = localStorage.getItem('access_token'); // Retrieve token from local storage
    const userProfile = JSON.parse(localStorage.getItem('userProfile')); // Get the user profile from local storage
    const [attendanceSessions, setAttendanceSessions] = useState([]); // State to store attendance sessions
    const [userAttendance, setUserAttendance] = useState([]); // State to store user's attendance records

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    // Fetch attendance sessions for the class
    const fetchAttendanceSessions = async () => {
        try {
            const response = await fetch(`${baseUrl}/api/class/attendance_sessions/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                // Filter sessions by class ID
                const filteredSessions = data.filter(session => session.class_model === parseInt(id_kelas));
                setAttendanceSessions(filteredSessions);

                // Fetch user attendance records for the filtered sessions
                fetchUserAttendance(filteredSessions.map(session => session.id));
            } else {
                throw new Error('Failed to fetch attendance sessions.');
            }
        } catch (error) {
            console.error("Error fetching attendance sessions:", error);
            Swal.fire("Gagal", "Terjadi kesalahan saat mengambil data sesi kehadiran.", "error");
        }
    };

    // Fetch user's attendance records for the given session IDs
    const fetchUserAttendance = async (sessionIds) => {
        try {
            const response = await fetch(`${baseUrl}/api/class/attendance_records/`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                },
            });
            if (response.ok) {
                const data = await response.json();
                // Filter records by user ID and session IDs
                const filteredRecords = data.filter(
                    record => record.student === userProfile.id && sessionIds.includes(record.attendance_session)
                );
                setUserAttendance(filteredRecords);
            } else {
                throw new Error('Failed to fetch attendance records.');
            }
        } catch (error) {
            console.error("Error fetching attendance records:", error);
            Swal.fire("Gagal", "Terjadi kesalahan saat mengambil data kehadiran.", "error");
        }
    };

    useEffect(() => {
        fetchAttendanceSessions(); // Fetch attendance sessions when component mounts
    }, [id_kelas]);

    return (
        <section className="section">
            <div className="section-header">
                <h1>Daftar Absensi Mahasiswa</h1>
            </div>
            {attendanceSessions.length > 0 ? (
                <table className="table table-striped mt-3">
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Tanggal Sesi</th>
                            <th>Deskripsi</th>
                            <th>Status Kehadiran</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendanceSessions.map((session, index) => {
                            // Find the user's attendance status for this session
                            const userRecord = userAttendance.find(record => record.attendance_session === session.id);
                            const status = userRecord ? userRecord.status : 'Belum Dicatat';

                            // Determine icon and styling based on status
                            let statusIcon;
                            let statusClass;
                            let statusName;

                            if (status === 'present') {
                                statusIcon = <i className="fas fa-check-circle"></i>;
                                statusClass = 'text-success';
                                statusName = 'Hadir';
                            } else if (status === 'absent') {
                                statusIcon = <i className="fas fa-times-circle"></i>;
                                statusClass = 'text-danger';
                                statusName = 'Absen';
                            } else if (status === 'excused') {
                                statusIcon = <i className="fas fa-exclamation-circle"></i>;
                                statusClass = 'text-warning';
                                statusName = 'Izin/Sakit';
                            } else {
                                statusIcon = <i className="fas fa-question-circle"></i>;
                                statusClass = 'text-secondary';
                                statusName = 'Invalid';
                            }

                            return (
                                <tr key={session.id}>
                                    <td>{index + 1}</td>
                                    <td>{new Date(session.session_date).toLocaleDateString()}</td>
                                    <td>{session.description}</td>
                                    <td className={statusClass}>
                                        {statusIcon} {statusName}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            ) : (
                <p>Tidak ada sesi absensi untuk kelas ini.</p>
            )}
        </section>
    );
}

export default KelasMhsAbsensiDetail;
